.date_picker_compooent {
  position: relative;
  margin: auto;
  height: 30px;
  width: 80px;
  display: flex;
  flex-flow: column;
  z-index: 100;
}
.date_picker_compooent .active {
  background: #8bb5eb !important;
}
.date_picker_compooent .date-picker-btn {
  background-color: #2c3e50;
  color: white;
  font-weight: 500;
  text-decoration: underline;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
}
.date_picker_compooent .datedreamer-range {
  display: flex;
  flex-flow: wrap;
}
.date_picker_compooent #datepicker {
  position: absolute;
  top: 100%;
}
.date_picker_compooent .hide-date-picker {
  display: none !important;
}/*# sourceMappingURL=DatePickerComponent.css.map */
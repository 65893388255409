.contactInfo{
    width: 100%;
    padding: 100px 0;
    background: #343a3f;
    box-shadow: 0 0 15px rgb(153, 153, 153);
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    .ContactListInfo{
        display: flex;
        justify-content: center;
        list-style: none;
        flex-flow: wrap;

        .InnerText{
            margin: auto;
            margin: 15px auto;
            padding: 0 15px;
            width: 50%;
            min-width: 360px;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            .title{
                font-size: 2em;
                font-weight: 500;
                margin: 15px 0;
            }
            .text{
                
            }

           
        }
        .contactdata{

            margin-bottom: 80px;
            display: flex;
            justify-content: center;
            flex-flow: column nowrap;
            min-width: 360px;

            div{
                margin: 10px;
                font-size: 1.4em;
                font-weight: 400;
            }
        }

        .FooterText{
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 1.5em;
            text-align: center;

        }
    }

}
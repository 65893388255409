.map_component .map{
    position: relative;
}
#map{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-create{
    position: absolute;
    height: 90%;
    width: 100%;
    bottom:0;
    left: 0;
}
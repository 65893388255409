.planes{
    margin: auto;
    margin-top: 100px;
    width: 95%;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 50px;

    section{
        width: 100%;    
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        >.title{
        width: 100%;
        font-size: 2.4em;
        font-weight: 700;
        margin: auto;
        display: flex;
        justify-content: center;
        color: rgb(14, 94, 140);
        text-decoration: underline;

        
    }
    
    .plan{
        margin-top: 50px;
        width: 30%;
        min-width: 360px;
        display: flex;
        flex-flow: nowrap column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-30deg, rgb(208, 235, 246) 0%, rgba(255,255,255,1) 100%);
        box-shadow: 0 0 15px 0 rgb(179, 179, 179);
        padding: 100px 0;

        .name{
            font-size:2em;
            font-weight: 500;
            text-align: center;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid rgb(170, 170, 170);
        }
        .cost{
            font-size: 1.7em;
            padding-top: 25px;
            font-weight: 700;
            color: #42709b;

            

        }
        ul{
            list-style: none;
            li{
                list-style:disc;
                margin: 15px 0;
                font-size: 1.2em;
                font-weight: 300;

            }
            padding: 20px 0;

        }

        .apply_plan{
            margin: auto;
            padding: 10px 20px;
            border: 1px solid;
            border-color: #6db8ff;
            background-color: transparent;
            color: #6db8ff;
            font-weight: 500;
            font-size: 1.3em;

        }

    }

}
}



.RecorridoList{
    width: 360px;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    right: -360px;
    background-color: white;
    box-shadow:  0 0 5px rgb(77, 77, 77);
    transition: .8s;
    overflow-y: scroll;
    header{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        position: relative;
        flex-flow: row nowrap;
        div{
            height: 50px;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            img{
            aspect-ratio: 1/1;
            width: 50px;
            
            &:hover{
                scale: 1.1;
                cursor: pointer;
    
            }
            }
        }
    }


    .list_clientes_header{

    margin: auto;
    display: flex;
    font-weight: 500;
    font-size: 1.2em;
    background-color: #15509e;
    color: white;
    padding: 20px 0;
    margin: 0;
    box-shadow: 0 0 3px 0 rgb(141, 141, 141);

    div{
        @extend .center;
        flex: 1;
       
    }

    text-align: center;
    @media screen and (max-width:620px) {

        font-size: 1em;
        
    }
   
}
.list_clientes{
    text-align: center;
    @media screen and (max-width:620px) {

        font-size: 0.8em;
        
    }
    li{
    display: flex;
    background-color: white;
    justify-content: space-around;
    border-bottom: 1px solid rgb(139, 139, 139);
    color: rgb(82, 82, 82);
    padding: 20px 0;
    margin: 20px 5px;
    

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 5px 0 rgb(163, 163, 163);
    }

    div{
        flex: 1;
        @extend .center;
        
    }

    
    .options img{
        width: 35px;
        margin:0 10px;
        aspect-ratio: 1/1;
        &:hover{
            scale: 1.1;
            cursor:pointer
        }
        @media screen and (max-width:400px) {
        margin: 0 auto;
            
        }
    }

    }
}


.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.recorridoslistBack{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    aspect-ratio: 1/1;
    border-radius: 70px;
    box-shadow: 0 0 3px rgb(177, 177, 177);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    margin: 10px;
    background-color: white;
    cursor: pointer;
    .icon-back{
        width: 40px;
        aspect-ratio: 1/1;
        background-image: url('../../../../../public/img/icon_close_route.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
        position: absoJlute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

    }
    .hide{
        background-image: url('../../../../../public/img/icon_arrow_left.png');
    }
}
&.hide{
    right: 0px;

}
}

.map_component{


    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 5px 0 rgb(108, 108, 108);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    z-index: 100;

    

    
        header{
            height: 65px;
            display: flex;
            align-items: center;
            width: 100%;        
            padding: 0; 
            box-shadow: 0 0 0 0;   
            .area-name{
                color:#15509e;
                font-size: 1.3em;
            }
            .form-table-name{
                margin: 0 20px;
                border: 0;
                border-bottom: 1px solid  #15509e;
                background-color: transparent;
                padding: 10px;
                flex: 1;

                @media screen and (min-width:360px){
                width: 70%;

                }
                &::placeholder{
                    color:#15509e;
                    font-size: 1.2em;
                }
    
            }


            .icon_close{
                height: 100%;
                aspect-ratio: 1/1;
                // position: absolute;
                @extend .center;
                // top: 0;
                // right: 0;
                // flex: 2;
                img{
                    width: 40px;
                    aspect-ratio: 1/1;
                    cursor: pointer;
                    &:hover{
                        scale: 1.1;
                    }
                }
            }

            
        }


        @media screen and (max-width:740px) {


            aspect-ratio:initial;
        }
        

        .buttons{

            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            margin: auto;
            width: 60%;
            background-color: white;
            display: flex;
            // grid-template-columns: 9fr 50px;
            border-radius: 5px;
            box-shadow: 0 0 5px 0 rgb(177, 177, 177);
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          

            .button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                margin:0 0 5px 0;
                img{
                    width: 40px;
                    padding: 10px;
                    height: 100%;
                    &:hover{

                        scale: 1.1;
                        cursor: pointer;
        
                    }
                }
            }


            

            
input[type='range'] {
  flex:1;

  margin: 10px 5%;
  

    
  }  
  input[type=range]::-webkit-slider-thumb {
    background-color: #15509e;
    width: 21px;
    height: 21px;
    border-radius: 50%;

  }
  
        }


}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

.window-container {
  width: 90%;
  height: 90vh;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.63);
  box-shadow: 0 0 15px rgb(119, 119, 119);
  margin: 25px auto;
}
.window-container header {
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 100px 1fr 200px;
}
.window-container header .logo {
  width: 80px;
  aspect-ratio: 1/1;
  background-image: url("/public/img/logo_gesnu_min.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.window-container header .loginbtn {
  width: 80%;
  height: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 3px solid #29aae1;
}
.window-container header .loginbtn a {
  color: black;
}/*# sourceMappingURL=main.css.map */
.ServicesCompany {
  width: 100%;
  min-width: 380px;
  margin: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 145px;
  row-gap: 100px;
  position: relative;
  box-shadow: 0 15px 15px rgb(158, 158, 158);
  padding-bottom: 100px;
  top: -200px;
}
@media screen and (max-width: 400px) {
  .ServicesCompany {
    top: -160px;
  }
}
.ServicesCompany .card {
  background-color: rgba(248, 250, 253, 0.788);
  box-shadow: 0 0 5px 0 rgb(222, 222, 222);
  border-radius: 10px;
  width: 100%;
  margin: 0 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-template-areas: "a" "b";
  padding: 50px;
  width: 95%;
  max-width: 400px;
  transition: 0.7s ease-out;
  opacity: 0;
  transform: translateY(30px);
}
.ServicesCompany .card .text {
  color: #15509e;
  grid-area: a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 2em;
  font-weight: 500;
}
.ServicesCompany .card .description {
  grid-area: b;
  color: #15509e;
  font-size: 1.3em;
  font-weight: 300;
  padding: 20px 0px;
}

.parallax_image {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("../img/backgrounds/Ciberseguridad-unir.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}/*# sourceMappingURL=StyleServicesCompany.module.css.map */
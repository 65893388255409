.why_Choose {
  width: 100%;
  padding: 150px 0;
  margin: 0 auto;
  box-shadow: 0 0px 55px 0 #d1dbe4;
  border: 1px solid rgba(130, 151, 170, 0.3921568627);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 30px auto;
  grid-template-areas: "a b" "c b";
}
@media screen and (max-width: 800px) {
  .why_Choose {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "b" "a" "c";
  }
  .why_Choose .drawable {
    aspect-ratio: 1/1;
    width: 80%;
    margin: auto;
    border-left: 0px !important;
  }
  .why_Choose .title {
    text-align: center;
  }
}
.why_Choose .title {
  grid-area: a;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  font-weight: 700;
}
.why_Choose .points {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  grid-area: c;
  display: flex;
  flex-flow: column nowrap;
}
.why_Choose .points li {
  padding: 5px 20px;
  text-align: center;
  font-weight: 300;
}
.why_Choose .drawable {
  background-image: url("/public/img/undraw_Projections_re_ulc6.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  grid-area: b;
  border-left: 1px solid rgb(199, 199, 199);
}/*# sourceMappingURL=WhyCoose.module.css.map */
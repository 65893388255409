.settings_component {
  width: 95%;
  max-width: 400px;
  min-height: 450px;
  max-height: 600px;
  overflow-y: auto;
  position: fixed;
  top: -600px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  box-shadow: 0 0 5px rgb(167, 167, 167);
  z-index: 1000;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80px 1fr 60px;
  transition: 0.8s;
  border-radius: 30px;
}
.settings_component .settings-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: underline;
  font-size: 1.3em;
  color: rgb(33, 92, 170);
}
.settings_component .confs_container_main {
  overflow-y: auto;
  max-height: 400px;
}
.settings_component .confs_container_main article {
  display: flex;
  flex-flow: row;
}
.settings_component .confs_container_main article > .title {
  min-width: 90px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  color: rgb(61, 58, 58);
}
.settings_component .confs_container_main article .configs_container .config {
  padding-top: 2px;
  margin-bottom: 30px;
}
.settings_component .confs_container_main article .configs_container .config .check {
  width: 20px;
  aspect-ratio: 1/1;
  margin: 0 5px;
}
.settings_component .confs_container_main article .configs_container .config .config_name {
  display: flex;
  align-items: center;
}
.settings_component .confs_container_main article .configs_container .config .config_description {
  font-size: 0.9em;
  padding: 0 5px;
  margin-top: 15px;
}
.settings_component .actions {
  box-shadow: 0 0 15px gray;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  border-radius: 20px;
}
.settings_component .actions > div {
  box-sizing: content-box;
  padding: 10px;
  font-weight: 500;
  color: rgb(16, 63, 124);
  margin-right: 15px;
  cursor: pointer;
}
.settings_component .actions > div.cancel {
  color: rgb(173, 51, 51);
}/*# sourceMappingURL=settings_component.css.map */
.card-recorrido {
  width: 70%;
  max-height: 80%;
  aspect-ratio: 1/1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px 1fr;
  box-shadow: 0 0 5px 0 rgb(108, 108, 108);
  z-index: 10;
  overflow: hidden;
}
@media screen and (max-width: 620px) {
  .card-recorrido {
    width: 95%;
    height: 95%;
    max-height: -moz-max-content;
    max-height: max-content;
  }
}
.card-recorrido header {
  height: 65px;
  padding: 0;
}
.card-recorrido header .exit {
  width: 30px;
  aspect-ratio: 1/1;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 15px;
  box-sizing: content-box;
  background-color: rgba(221, 90, 90, 0.4);
}
.card-recorrido header .exit img {
  width: 100%;
  height: 100%;
}
.card-recorrido header .exit:hover {
  cursor: pointer;
}

.reporte {
  width: 95%;
  max-width: 460px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: white;
  z-index: 100;
  left: 0;
  overflow: hidden;
  transition: 0.8s cubic-bezier(0, 0, 0.02, 1);
}
.reporte.hide {
  left: -460px;
}
.reporte::after {
  content: "";
  width: 2px;
  height: 100%;
  background-color: white;
  position: absolute;
}
.reporte .close {
  width: 60px;
  aspect-ratio: 1/1;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(245, 188, 188);
  z-index: 1;
}
.reporte .close img {
  width: 100%;
  aspect-ratio: 1/1;
  padding: 20px;
}
.reporte .close:hover {
  cursor: pointer;
  background-color: rgb(245, 188, 188);
}
.reporte .reporte_img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.reporte .description {
  width: 100%;
  height: 100%;
  padding: 80px 20px;
  font-size: 1.2em;
  background-color: white;
  position: absolute;
  bottom: -100%;
  left: 0;
  transition: 0.5s ease;
}
.reporte .description .fecha {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 50px 30px;
  font-size: 1.2em;
}

.hide_report_buttom {
  position: absolute;
  bottom: 0px;
  right: 0;
  margin: 30px;
  padding: 25px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 3px rgb(94, 94, 94);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  transform: rotate(-90deg);
}
.hide_report_buttom img {
  width: 30px;
  aspect-ratio: 1/1;
}/*# sourceMappingURL=card_recorrido.module.css.map */
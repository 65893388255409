header{
    background-color: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    box-sizing: content-box;
    padding: 25px 0;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width:620px){
        .title{
        font-size: 1.2em;
        }}
    .title{
        margin: 5px 0;
        width: 95%;
        max-width: 600px;
        cursor: pointer;
        transition: .6s;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-flow: wrap;
        font-size: 1.3em;
        align-items: center;
       
    }
    .tools{
        display: flex;
        flex-flow: row;

        div,img{
            width: 30px;
            height: 30px;
            cursor :pointer;
        }
    }
}
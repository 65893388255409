@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}

.home_header {
  background-color: rgba(255, 255, 255, 0.774);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 8fr minmax(150px, 1fr);
  grid-template-rows: 90px;
  transform: translateY(-100%);
  transition: 0.7s ease-in-out;
  opacity: 0;
}
@media screen and (max-width: 720px) {
  .home_header {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 90px 90px;
    grid-template-areas: "a b" "c c";
    padding: 0;
  }
  .home_header .logo {
    grid-area: a;
  }
  .home_header .nav {
    grid-area: c;
    width: 100%;
  }
}
@media screen and (max-width: 720px) and (max-width: 370px) {
  .home_header .nav > a {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 720px) {
  .home_header .login {
    grid-area: b;
    justify-self: flex-end;
    padding: 15px;
  }
}
.home_header .logo {
  min-width: 100px;
  height: 70%;
  align-self: center;
  background-image: url("/public/img/logo_gesnu_min.png");
  margin: 0 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.home_header .nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
}
.home_header .nav a {
  text-decoration: none;
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-self: center;
  align-items: center;
  color: black;
}
.home_header .nav a.focus {
  border-bottom: solid 1px #15509e;
  font-weight: 600;
  color: #15509e;
}
.home_header .login {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 3px solid #29aae1;
  height: 80%;
  max-width: 150px;
  width: 80%;
}
.home_header .login a {
  color: black;
}/*# sourceMappingURL=principal_page.module.css.map */
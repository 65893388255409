.RecorridoList {
  width: 360px;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  right: -360px;
  background-color: white;
  box-shadow: 0 0 5px rgb(77, 77, 77);
  transition: 0.8s;
  overflow-y: scroll;
}
.RecorridoList header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: relative;
  flex-flow: row nowrap;
}
.RecorridoList header div {
  height: 50px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.RecorridoList header div img {
  aspect-ratio: 1/1;
  width: 50px;
}
.RecorridoList header div img:hover {
  scale: 1.1;
  cursor: pointer;
}
.RecorridoList .list_clientes_header {
  margin: auto;
  display: flex;
  font-weight: 500;
  font-size: 1.2em;
  background-color: #15509e;
  color: white;
  padding: 20px 0;
  margin: 0;
  box-shadow: 0 0 3px 0 rgb(141, 141, 141);
  text-align: center;
}
.RecorridoList .list_clientes_header div {
  flex: 1;
}
@media screen and (max-width: 620px) {
  .RecorridoList .list_clientes_header {
    font-size: 1em;
  }
}
.RecorridoList .list_clientes {
  text-align: center;
}
@media screen and (max-width: 620px) {
  .RecorridoList .list_clientes {
    font-size: 0.8em;
  }
}
.RecorridoList .list_clientes li {
  display: flex;
  background-color: white;
  justify-content: space-around;
  border-bottom: 1px solid rgb(139, 139, 139);
  color: rgb(82, 82, 82);
  padding: 20px 0;
  margin: 20px 5px;
}
.RecorridoList .list_clientes li:hover {
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgb(163, 163, 163);
}
.RecorridoList .list_clientes li div {
  flex: 1;
}
.RecorridoList .list_clientes li .options img {
  width: 35px;
  margin: 0 10px;
  aspect-ratio: 1/1;
}
.RecorridoList .list_clientes li .options img:hover {
  scale: 1.1;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .RecorridoList .list_clientes li .options img {
    margin: 0 auto;
  }
}
.RecorridoList .center, .RecorridoList .list_clientes_header div, .RecorridoList .list_clientes li div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.RecorridoList .recorridoslistBack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  aspect-ratio: 1/1;
  border-radius: 70px;
  box-shadow: 0 0 3px rgb(177, 177, 177);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  margin: 10px;
  background-color: white;
  cursor: pointer;
}
.RecorridoList .recorridoslistBack .icon-back {
  width: 40px;
  aspect-ratio: 1/1;
  background-image: url("../../../../../public/img/icon_close_route.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  position: absoJlute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.RecorridoList .recorridoslistBack .hide {
  background-image: url("../../../../../public/img/icon_arrow_left.png");
}
.RecorridoList.hide {
  right: 0px;
}/*# sourceMappingURL=RoutesListComponent.css.map */
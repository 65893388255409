.date_picker_compooent{
    position: relative;
    margin: auto;
    height: 30px;
    width: 80px;
    display: flex;
    flex-flow: column;
    z-index: 100;
    .active{
        background: #8bb5eb !important;
    }
        .date-picker-btn{
            background-color: #2c3e50;
            color: white;
            font-weight: 500;
            text-decoration: underline;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            padding: 15px;
            border-radius: 4px;
            cursor: pointer;
            }
            .datedreamer-range{
                display: flex;
                flex-flow: wrap;
                
                          
            }

        #datepicker{
            position: absolute;
            top: 100%;
            
            
        }
        .hide-date-picker{
            display: none !important;
        }
    }
   

.slider{
    padding-top: 80px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "b a";
    background: linear-gradient(60deg, #15509e 0%, #29aae1 100%);
    height: 100vh;


    @media screen and (max-width:1080px) {
        grid-template-columns: 100%;
        grid-template-rows: 4fr 6fr;
        grid-template-areas: "a"
                             "b";
        

    }
    @media screen and (max-width:800px) {
        padding-top: 170px;
        .dialog {

            width: 95%;

            > .title{
            font-size: 1.6em;
        }}
        

    }

    .drawable{
        transition: .7s ease-out;
        background-image: url("/public/img/ilustracion2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        grid-area: a;
        transform: translateX(30px);
        opacity: 0;
        
    }

}

.dialog{
    transition: .7s ease-out;
    transform: translateX(-30px);
    opacity: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-self: center;
    align-self: center;
    width: 90%;
    margin: 0px 0;
    grid-area: b;
    .title{
        font-size: 3em;
        padding: 0 25px;
        color: rgb(243, 243, 243);
        font-weight: 800;
    }
    .textContain{
        margin:  20px 0;
        color: rgb(243, 243, 243);

        padding: 0 25px;
        
    }
}